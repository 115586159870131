<template>
  <div v-if="egressProfileMatterDescriptor">
    <b-table-simple
      v-if="
        (matterMiddleDescriptor && matterMiddleDescriptor.is_visible) ||
        allows_crud
      "
      bordered
      responsive
      class="mt-2"
    >
      <b-thead>
        <b-tr>
          <b-th>
            <div class="d-flex align-items-center justify-content-center">
              <div class="w-100">
                {{ egressProfileMatterDescriptor.title }}
              </div>
              <template
                v-if="
                  allows_crud &&
                  isClosedOldMatterPartial(
                    egressProfileMatterDescriptor.internal_use_id
                  )
                "
              >
                <span v-if="matterMiddleDescriptor">
                  <b-button
                    size="sm"
                    class="ml-1 p-0"
                    variant="none"
                    @click="showDescriptor(matterMiddleDescriptor)"
                  >
                    <template v-if="matterMiddleDescriptor.is_visible">
                      <b-icon
                        class="btn-actions"
                        icon="eye"
                        v-b-tooltip.v-secondary.noninteractive="`Mostrar`"
                        scale="1"
                      ></b-icon>
                    </template>
                    <template v-else>
                      <b-icon
                        class="btn-actions"
                        v-b-tooltip.v-secondary.noninteractive="`Ocultar`"
                        icon="eye-slash"
                        scale="1"
                      ></b-icon>
                    </template>
                  </b-button>
                </span>
                <button-edit
                  class="ml-1 p-0"
                  @click="slotUpdatedTitle(egressProfileMatterDescriptor)"
                  v-b-tooltip.v-secondary.noninteractive="`Editar`"
                >
                </button-edit>
                <b-button
                  v-if="show_deleted_button && matterMiddleDescriptor"
                  class="ml-1 mr-1 p-0"
                  variant="none"
                  @click="deleteDescriptors(matterMiddleDescriptor)"
                  v-b-tooltip.v-secondary.noninteractive="`Limpiar Contenido`"
                >
                  <b-icon-x-square></b-icon-x-square>
                </b-button>
                <span
                  v-else-if="
                    institution &&
                    institution.internal_use_id == 'duoc_uc' &&
                    egressProfileMatterDescriptor &&
                    egressProfileMatterDescriptor.internal_use_id != null
                  "
                  v-b-tooltip.v-secondary.auto.noninteractive="
                    `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
                  "
                >
                  <button-delete disabled></button-delete>
                </span>
              </template>
            </div>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>
            <template v-if="matterMiddleDescriptor">
              <div
                class="descriptor-body pt-2 pb-3 rich-text-content"
                v-html="matterMiddleDescriptor.description"
              ></div>
              <div
                v-if="
                  allows_crud &&
                  isClosedOldMatterPartial(
                    egressProfileMatterDescriptor.internal_use_id
                  )
                "
              >
                <button-edit
                  class="btn-center"
                  @click="
                    $bvModal.show(
                      `${egressProfileMatterDescriptor.id}edit-description-modal-${matterMiddleDescriptor.id}`
                    )
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `${egressProfileMatterDescriptor.title}`
                  "
                >
                </button-edit>
                <b-modal
                  :id="`${egressProfileMatterDescriptor.id}edit-description-modal-${matterMiddleDescriptor.id}`"
                  :title="`${egressProfileMatterDescriptor.title}`"
                  size="lg"
                  hide-footer
                  no-enforce-focus
                >
                  <NewRichTextEditor
                    :Object="matterMiddleDescriptor"
                    :Text="matterMiddleDescriptor.description"
                    :permit_blank="true"
                    @save="updateDescription"
                    @close="
                      $bvModal.hide(
                        `${egressProfileMatterDescriptor.id}edit-description-modal-${matterMiddleDescriptor.id}`
                      )
                    "
                  ></NewRichTextEditor>
                </b-modal>
              </div>
            </template>
            <template v-else>
              <div
                v-if="
                  allows_crud &&
                  isClosedOldMatterPartial(
                    egressProfileMatterDescriptor.internal_use_id
                  )
                "
              >
                <button-add
                  class="btn-center"
                  @click="
                    $bvModal.show(
                      `${egressProfileMatterDescriptor.id}add-description-modal-${egressProfileMatterDescriptor.id}`
                    )
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `${egressProfileMatterDescriptor.title}`
                  "
                >
                </button-add>
                <b-modal
                  :id="`${egressProfileMatterDescriptor.id}add-description-modal-${egressProfileMatterDescriptor.id}`"
                  :title="`${egressProfileMatterDescriptor.title}`"
                  size="lg"
                  hide-footer
                  no-enforce-focus
                >
                  <NewRichTextEditor
                    :Type="egressProfileMatterDescriptor"
                    :permit_blank="true"
                    @save="createDescription"
                    @close="
                      $bvModal.hide(
                        `${egressProfileMatterDescriptor.id}add-description-modal-${egressProfileMatterDescriptor.id}`
                      )
                    "
                  ></NewRichTextEditor>
                </b-modal>
              </div>
            </template>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-modal
      :id="`${egressProfileMatterDescriptor.id}-title-modal`"
      :title="`Editar título`"
      size="lg"
      @hide="resetTitleModal"
      hide-footer
      no-enforce-focus
    >
      <b-form-group
        label-cols="0"
        label-cols-sm="2"
        label-for="descriptor-title-input"
      >
        <template #label>
          <span id="descriptor-title-input" class="descriptor-title-label"
            >Título</span
          >
        </template>
        <div>
          <div>
            <b-form-input
              id="descriptor-title-input"
              v-model="title_proccess.title"
              aria-describedby="input-title-live-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-title-live-feedback">
              <div>Este campo debe tener al menos 1 caracteres.</div>
            </b-form-invalid-feedback>
          </div>
          <div class="mt-2 d-flex w-100">
            <template>
              <template v-if="title_proccess.internal_use_id == null">
                <b-form-checkbox
                  class="descriptor-title-check w-100"
                  v-model="title_proccess.is_default"
                  >Predeterminado
                  <InfoTooltip
                    class="selection-info ml-0"
                    :tooltip_text="`${Text_info_tooltip}`"
                  >
                  </InfoTooltip>
                </b-form-checkbox>
              </template>
              <template v-else>
                <b-form-checkbox
                  class="descriptor-title-check w-100"
                  :checked="title_proccess.is_default"
                  :disabled="true"
                  >Predeterminado
                  <InfoTooltip
                    class="selection-info ml-0"
                    :tooltip_text="`${Text_info_tooltip}`"
                  >
                  </InfoTooltip>
                </b-form-checkbox>
              </template>
            </template>
          </div>
        </div>
      </b-form-group>
      <b-button
        size="sm"
        variant="primary"
        class="float-right"
        @click="save(title_proccess)"
      >
        Guardar Título
      </b-button></b-modal
    >
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "DuocSpecialDescriptor",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    internal_use_id: {
      type: String,
      required: true,
    },
    matter: {
      type: Object,
      required: true,
    },
    PagePosition: {
      type: Number,
      default: 1,
    },
    Text_info_tooltip: {
      type: String,
      default: "",
    },
    Title_text: {
      type: String,
      default: "",
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    show_deleted_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title_proccess: {
        id: generateUniqueId(),
        title: "",
        page_position: this.PagePosition,
        is_default: true,
        internal_use_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      egress_profile_matter_descriptors:
        names.EGRESS_PROFILE_MATTER_DESCRIPTORS,
      m_middle_descriptors: names.M_MIDDLE_DESCRIPTORS,
      matters: names.MATTERS,
    }),
    oldMatter() {
      if (!this.matter) return null;
      return this.matters.find((x) => x.id == this.matter.matter);
    },
    matterMiddleDescriptors() {
      return this.m_middle_descriptors.filter(
        (x) => x.matter == this.matter.matter
      );
    },
    egressProfileMatterDescriptor() {
      const matter_middle = this.matterMiddleDescriptors.map(
        (x) => x.descriptor
      );
      return this.egress_profile_matter_descriptors.find(
        (x) =>
          (x.is_default || matter_middle.includes(x.id)) &&
          ((this.$equals(
            x.internal_use_id,
            "duoc_pmd_te_evaluation_situation"
          ) &&
            this.internal_use_id ==
              "duoc_pmd_te_evaluation_situation_expanded") ||
            (this.$equals(x.internal_use_id, "duoc_pmd_te_orientations") &&
              this.internal_use_id == "duoc_pmd_te_orientations_expanded") ||
            (this.$equals(
              x.internal_use_id,
              "duoc_pmd_matter_description_superior"
            ) &&
              this.internal_use_id == "duoc_pmd_matter_description") ||
            (this.$equals(
              x.internal_use_id,
              "duoc_key_suggestions_and_warnings"
            ) &&
              this.internal_use_id ==
                "duoc_key_suggestions_and_warnings_expanded"))
      );
    },
    matterMiddleDescriptor() {
      if (!this.egressProfileMatterDescriptor) return null;
      return this.matterMiddleDescriptors.find(
        (x) => x.descriptor == this.egressProfileMatterDescriptor.id
      );
    },
  },
  methods: {
    showDescriptor(item) {
      this.$emit("updateState", item);
    },
    createDescription(text, object) {
      this.$emit("createDescription", text, object);
    },
    updateDescription(object, text) {
      this.$emit("updateDescription", object, text);
    },
    slotUpdatedTitle(item) {
      this.title_proccess.id = item.id;
      this.title_proccess.title = item.title;
      this.title_proccess.is_default = item.is_default;
      if (item.internal_use_id != null)
        this.title_proccess.internal_use_id = item.internal_use_id;
      this.$bvModal.show(
        `${this.egressProfileMatterDescriptor.id}-title-modal`
      );
    },
    deleteDescriptors(element) {
      this.$emit("deleteDescriptorsContent", element);
    },
    resetTitleModal() {
      this.title_proccess.id = generateUniqueId();
      this.title_proccess.title = "";
      this.title_proccess.is_default = false;
      this.title_proccess.internal_use_id = null;
    },
    save(title) {
      title.page_position = this.PagePosition;
      if (isNaN(title.id)) {
        title.internal_use_id = null;
        this.$emit("createTitle", title);
        this.$bvModal.hide(
          `${this.egressProfileMatterDescriptor.id}-title-modal`
        );
      } else {
        this.$swal({
          title: "¿Está seguro de que desea editar el Descriptor?",
          text: `"Esto podria afectar a otros ${this.Title_text}!"`,
          type: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            this.$emit("updateTitle", title);
            this.$bvModal.hide(
              `${this.egressProfileMatterDescriptor.id}-title-modal`
            );
          }
        });
      }
    },
    isClosedOldMatterPartial(internal_use_id) {
      if (this.oldMatter) {
        if (this.institution && this.institution.internal_use_id == "duoc_uc") {
          if (
            this.$equals(internal_use_id, "duoc_pmd_te_evaluation_situation") ||
            this.$equals(internal_use_id, "duoc_pmd_te_orientations") ||
            this.$equals(
              internal_use_id,
              "duoc_pmd_matter_description_superior"
            ) ||
            this.$equals(internal_use_id, "duoc_key_suggestions_and_warnings")
          ) {
            return !this.oldMatter.descriptors_locked_view;
          } else return true;
        } else {
          return !this.oldMatter.descriptors_locked_view;
        }
      } else return true;
    },
  },
  created() {},
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
table {
  width: 100%;
  table-layout: auto;
}
table td {
  border: 1px solid black !important;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table th {
  vertical-align: middle;
  border: 1px solid black !important;
  background-color: var(--primary-color) !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.th-header {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: var(--primary-font-size);
}
.descriptor-body {
  display: block;
  text-align: justify;
  padding: 0.4em;
  padding-inline: 1em;
  font-size: var(--secondary-font-size);
}
.descriptor-body >>> p {
  margin-bottom: 0 !important;
}
</style>

